<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { TimeMechs } from '~/types/mechs';

// Components
import ObjectiveGraph from '~/app-modules/dashboard/components/graphs/ObjectiveGraph.vue';
import OverallConsumptionGraph from '~/app-modules/dashboard/components/graphs/OverallConsumptionGraph.vue';
import ObjectiveManageModal from '~/app-modules/dashboard/components/modals/ObjectiveManageModal.vue';

const { t } = useI18n();
const { getAnalyticDataByMonth, getObjectiveConsumption } = useApi();

const objectiveManageModalStatus = ref(false);
const state = reactive({
  isLoading: false,
  timeMesh: TimeMechs.DAILY,
  consumption: {
    timestamps: [] as Array<string>,
    enedis: [] as Array<number>,
    grdf: [] as Array<number>,
  },
  objectiveConsumption: [
    {
      consumption_kWh: [] as Array<number>,
      cumulative_consumption_kWh: [] as Array<number>,
      cost: [] as Array<number>,
      timestamps: [] as Array<string>,
      estimatedObjective: [] as Array<number>,
    },
  ],
  objectives: [] as Array<ConsumptionObjective>,
  chartType: 'bar',
  dataType: 'consumption' as 'consumption' | 'euro',
  toggle: false,
  selectedTab: 0,
});

const data = reactive({
  enedisRaw: Object as any,
  grdfRaw: Object as any,
});

const getTabs = computed(() => {
  const tabs: Array<{ label: string; id: number }> = [];

  const enedisAnalytics = state.objectives.filter((a) => a?.analytic?.type === 'enedis');
  const grdfAnalytics = state.objectives.filter((a) => a?.analytic?.type === 'grdf');

  state.objectives
    .filter((objective) => {
      if (userStore().getCurrentUser?.role === 'admin_app') {
        return globalStore().getSelectedClient === objective.client_id;
      }
      return true;
    })
    .forEach((objective, index) => {
      if (objective.analytic.type === 'enedis')
        tabs.push({
          id: index,
          label: objective.name ?? `${objective.analytic.name}${enedisAnalytics.length > 1 ? ` - ${t('global.objective')} ${index}` : ''}`,
        });
      if (objective.analytic.type === 'grdf')
        tabs.push({
          id: index,
          label: objective.name ?? `${objective.analytic.name}${grdfAnalytics.length > 1 ? ` - ${t('global.objective')} ${index}` : ''}`,
        });
    });

  return tabs;
});

const openObjectiveManageModal = () => {
  objectiveManageModalStatus.value = true;
};

function sortObjectivesByAnalyticType() {
  state.objectives.sort((a, b) => {
    if (a.analytic.type === 'enedis') return -1;
    if (b.analytic.type === 'enedis') return 1;
    if (a.analytic.type === 'grdf') return -1;
    if (b.analytic.type === 'grdf') return 1;
    return 0;
  });
}

async function switchMode(dataType: boolean) {
  if (dataType) {
    state.consumption.enedis = data.enedisRaw?.cost ?? undefined;
    state.consumption.grdf = data.grdfRaw?.cost ?? undefined;
  } else {
    state.consumption.enedis = data.enedisRaw?.consumption_kWh ?? undefined;
    state.consumption.grdf = data.grdfRaw?.consumption_kWh ?? undefined;
  }
}

async function loadData() {
  state.isLoading = true;

  if (consumptionObjectiveStore().getConsumptionObjectives.length) {
    state.objectives = consumptionObjectiveStore().getConsumptionObjectives;

    state.objectives = state.objectives.map((o) => {
      return {
        ...o,
        analytic: consumptionObjectiveStore().getAnalyticForConsumptionObjective(o.id),
      };
    });
    sortObjectivesByAnalyticType();

    const promises: Array<Promise<any>> = [];
    for (let index = 0; index < state.objectives.length; index++) {
      const objective = state.objectives[index];
      const consumption = getObjectiveConsumption(objective.id);
      promises.push(consumption);
    }
    state.objectiveConsumption = await Promise.all(promises);
  } else {
    const consumptionData = await getAnalyticDataByMonth();

    const enedisConsumption = consumptionData.find((data) => data.meter.meter_type.name === 'enedis')?.consumption;
    data.enedisRaw = {
      consumption_kWh: enedisConsumption?.map((consumption) => consumption.consumption_kwh),
      cost: enedisConsumption?.map((consumption) => consumption.cost),
    };

    const grdfConsumption = consumptionData.find((data) => data.meter.meter_type.name === 'grdf')?.consumption;
    data.grdfRaw = {
      consumption_kWh: grdfConsumption?.map((consumption) => consumption.consumption_kwh),
      cost: grdfConsumption?.map((consumption) => consumption.cost),
    };

    state.consumption = {
      timestamps: consumptionData[0].consumption.map((c) => c.date),
      enedis: data.enedisRaw?.consumption_kWh ?? undefined,
      grdf: data.grdfRaw?.consumption_kWh ?? undefined,
    };
  }

  state.isLoading = false;
}

watch(() => state.toggle, switchMode);

watch(
  () => consumptionObjectiveStore().getConsumptionObjectives,
  () => loadData(),
  {
    immediate: false,
  },
);

onMounted(() => {
  loadData();
});
</script>

<template>
  <div class="flex flex-col w-full h-full bg-gray-50">
    <ui-header :title="t('global.dashboard')">
      <template #actions>
        <ui-button left-icon="Plus" @click="openObjectiveManageModal()">
          {{ $t('dashboard.create_objective') }}
        </ui-button>
      </template>
    </ui-header>
    <div class="flex justify-start flex-col gap-4 p-4 overflow-y-auto">
      <template v-if="!state.objectives[0]?.id">
        <ui-card :title="$t('dashboard.overall_consumption')">
          <overall-consumption-graph
            :type="state.chartType"
            :labels="state.consumption.timestamps"
            :consumption="state.consumption"
            :loading="state.isLoading"
            :toggle="state.toggle"
            @change-data-type="switchMode"
          />
        </ui-card>
      </template>
      <template v-else>
        <template v-if="state.objectives.length > 1">
          <ui-card>
            <ui-tab v-model="state.selectedTab" :list-tabs="getTabs" />
          </ui-card>
        </template>
        <template v-if="!state.isLoading">
          <template v-for="(_, index) in getTabs" :key="index">
            <objective-graph
              v-if="state.selectedTab === index"
              :objective-name="state.objectives[index].name"
              :consumption="state.objectiveConsumption[index]"
              :loading="state.isLoading"
            />
          </template>
        </template>
        <ui-loader v-else-if="state.isLoading" />
      </template>
    </div>
  </div>

  <objective-manage-modal v-model="objectiveManageModalStatus" />
</template>
