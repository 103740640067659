<script setup lang="ts">
import type { ChartTypeRegistry } from 'chart.js';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  type: {
    type: String as () => keyof ChartTypeRegistry,
    required: true,
  },
  labels: {
    type: Array<string>,
    required: true,
  },
  consumption: {
    type: Object as PropType<{
      timestamps: Array<string>;
      enedis: Array<number>;
      grdf: Array<number>;
    }>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  toggle: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  'change-data-type': [toggle: boolean];
}>();

const state = reactive({
  toggle: false,
});
const { t } = useI18n();

const options = {
  scales: {
    y: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        stepSize: 1000,
      },
      title: {
        text: (_value: any) => {
          if (props.toggle) return t('analytics.consumption_in_euro');
          return t('analytics.consumption_in_kwh');
        },
        display: true,
        padding: 12,
      },
    },
    x: {
      stacked: true,
      ticks: {
        callback: (value: any) => {
          const date = new Date(props.labels[value]);
          return date.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' });
        },
        stepSize: 1,
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y || 0;
          if (props.toggle) return `${label}: ${Math.round(value)} €`;
          return `${label}: ${Math.round(value)} kWh`;
        },
        title: (context: any) => {
          const date = new Date(context[0].label);
          return date.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' });
        },
      },
    },
  },
};

watch(
  () => state.toggle,
  () => emit('change-data-type', state.toggle),
  { immediate: true },
);
</script>

<template>
  <div class="flex flex-1 flex-col-reverse lg:flex-row gap-2.5 lg:gap-6">
    <div class="flex flex-col w-full gap-4 rounded lg:rounded-lg">
      <div class="max-h-xl">
        <template v-if="!props.loading">
          <ui-chart
            type="bar"
            :data="{
              labels: props.labels, //[$t('months.january'), $t('months.february'), $t('months.march'), $t('months.april'), $t('months.may'), $t('months.june'), $t('months.july'), $t('months.august'), $t('months.september'), $t('months.october'), $t('months.november'), $t('months.december')],
              datasets: [
                {
                  label: t('global.gaz'),
                  data: props.consumption.grdf,
                  backgroundColor: '#EDF6FD',
                  borderColor: '#15649D',
                  borderWidth: 1,
                },
                {
                  label: t('global.electricity'),
                  data: props.consumption.enedis,
                  backgroundColor: '#F0FDF4',
                  borderColor: '#1BA774',
                  borderWidth: 1,
                },
              ].filter((dataset) => dataset.data && dataset.data.length > 0),
            }"
            :options="options"
            :plugins="{ 'chartjs-plugin-zoom': true }"
          />
        </template>
        <div v-else class="flex justify-center items-center w-full h-[50vh]">
          <ui-loader />
        </div>
      </div>
    </div>
  </div>
</template>
