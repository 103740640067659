import { useI18n } from 'vue-i18n';

export const useAsOption = () => {
  const { t } = useI18n();

  const siteAsOption = computed(() => {
    const sites = siteStore().getSites;
    return sites.map((site) => ({
      label: site.name,
      key: site.id,
    }));
  });

  const meterWithEnergyTypeAsOption = computed(() => {
    const meters = meterStore().getMeters;
    return meters.map((meter) => ({
      label: meter.area.name + ' - ' + t(`global.energy_type.${meter.meter_type.energy_type}`),
      key: meter.id,
    }));
  });

  const energyBySiteAsOption = (siteId: number) => {
    const rootAreas = areaStore().getRootAreas.filter((area) => area.site_id === siteId);
    if (!rootAreas[0]) return [];
    return rootAreas[0].meters.map((meter) => {
      return {
        label: t(`global.energy_type.${meter.meter_type.energy_type}`),
        key: meter.id,
      };
    });
  };

  return {
    siteAsOption,
    meterWithEnergyTypeAsOption,
    energyBySiteAsOption,
  };
};
