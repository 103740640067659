<script setup lang="ts">
import { useI18n } from 'vue-i18n';

// Composables
const { t } = useI18n();

const props = defineProps({
  objectiveName: String as PropType<string>,
  consumption: {
    type: Object as PropType<{
      consumption_kWh: Array<number>;
      cumulative_consumption_kWh: Array<number>;
      cumulative_cost: Array<number>;
      cost: Array<number>;
      timestamps: Array<string>;
      estimated_objective: Array<number>;
      estimated_objective_cost: Array<number>;
    }>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const currentValueIndex = computed(() => {
  return props.consumption.cumulative_consumption_kWh.findIndex((element) => element === null) - 1;
});

const currentConsumption = computed(() => {
  return props.consumption.cumulative_consumption_kWh[currentValueIndex.value];
});

const currentCost = computed(() => {
  return props.consumption.cumulative_cost[currentValueIndex.value];
});

const state = reactive({
  toggle: false,
});

const unit = computed(() => {
  return state.toggle ? '€' : 'kWh';
});

const navigatorLanguage = window.navigator.language;

const options = {
  spanGaps: true,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  scales: {
    y: {
      title: {
        text: (_value: any) => {
          return state.toggle ? t('analytics.consumption_in_euro') : t('analytics.consumption_in_kwh');
        },
        display: true,
      },
      grace: '10%',
      gridLines: {
        borderDash: [5, 5],
        color: '#E7E8E9',
        display: true,
        zeroLineColor: 'gray',
        zeroLineWidth: 1,
        drawBorder: false,
      },
    },
    x: {
      type: 'time',
      ticks: {},
      offset: false,
      grid: {
        offset: false,
      },
    },
  },
  plugins: {
    mode: 'index',
    tooltip: {
      callbacks: {
        title: (context: any) => {
          if (context.length > 0) return context[0].label.split(',').slice(0, -1).join(',');
        },
        label: (context: any) => `${context.dataset.label || ''}: ${Math.round(context.parsed.y || 0)} ${unit.value}`,
        footer: (context: any) => {
          if (context.length > 1) {
            const delta = Number.parseInt(context[0].parsed.y) - Number.parseInt(context[1].parsed.y);
            const deltaPercentage = (delta / Number.parseInt(context[1].parsed.y)) * 100;
            return context.length > 1 ? `Diff: ${delta} ${unit.value} (${delta === 0 ? 0 : deltaPercentage.toFixed(2)}%)` : '';
          }
        },
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

function getGradient(ctx, chartArea) {
  let width, height, gradient;

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, '#1BA77410');
    gradient.addColorStop(0.2, '#1BA77450');
    gradient.addColorStop(1, '#1BA77490');
  }

  return gradient;
}
</script>

<template>
  <div class="flex flex-1 flex-col-reverse gap-4">
    <ui-card :title="$t('dashboard.evolution_of_your_consumption')">
      <template #header>
        <div class="flex flex-row items-center gap-2">
          <ui-input-toggle v-model="state.toggle" hide-details />
          <p class="text-gray-400 text-sm">
            {{ state.toggle ? $t('analytics.consumption_in_euro') : $t('analytics.consumption_in_kwh') }}
          </p>
        </div>
      </template>
      <div class="max-h-xl">
        <template v-if="!props.loading">
          <ui-chart
            type="bar"
            :data="{
              labels: consumption.timestamps,
              datasets: [
                {
                  type: 'line',
                  label: t('global.true_cumulative_consumption'),
                  data: state.toggle ? consumption.cumulative_cost : consumption.cumulative_consumption_kWh,
                  backgroundColor(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) return;
                    return getGradient(ctx, chartArea);
                  },
                  borderColor: '#4EBB93',
                  pointHoverRadius: 5,
                  borderWidth: 1,
                  fill: 'start',
                },
                {
                  type: 'line',
                  label: t('global.objective') + ' ' + objectiveName,
                  data: state.toggle ? consumption.estimated_objective_cost : consumption.estimated_objective,
                  borderColor: '#85A5FF',
                  backgroundColor: '#85A5FF',
                  pointHoverRadius: 5,
                  borderDash: [4, 4],
                  borderWidth: 2,
                },
              ],
            }"
            :options="options"
            :plugins="{ 'chartjs-plugin-zoom': true }"
          />
        </template>
        <ui-loader v-else />
      </div>
    </ui-card>

    <div class="grid grid-cols-2 w-full gap-4">
      <ui-stat-card
        :title="$t('analytics.total_consumption')"
        type="consumption"
        :data="currentConsumption"
        :secondary-data="`(${new Intl.NumberFormat(navigatorLanguage, {
          maximumFractionDigits: 2,
          signDisplay: 'exceptZero',
          style: 'percent',
        }).format(currentConsumption / consumption.estimated_objective[currentValueIndex] - 1)})`"
      />
      <ui-stat-card
        :title="$t('global.cost')"
        type="euro"
        :data="currentCost"
        :secondary-data="`(${new Intl.NumberFormat(navigatorLanguage, {
          maximumFractionDigits: 2,
          signDisplay: 'exceptZero',
          style: 'percent',
        }).format(currentCost / consumption.estimated_objective_cost[currentValueIndex] - 1)})`"
      />
    </div>
  </div>
</template>
