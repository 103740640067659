<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

defineProps<{
  name?: string;
  label?: string;
  hideDetails?: boolean;
}>();

const value = defineModel<boolean>();
</script>

<template>
  <div class="flex" :class="[hideDetails ? '' : 'mb-[16px]']">
    <SwitchGroup>
      <Switch
        v-model="value"
        :class="[
          value ? 'bg-[#1BA774]' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#1BA774] focus:ring-offset-2',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
      <SwitchLabel as="span" class="ml-3 text-sm flex items-center">
        <span class="font-medium text-gray-900">{{ label }}</span>
      </SwitchLabel>
    </SwitchGroup>
  </div>
</template>
