import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import type { InsertObjective } from '~/types/objective';
import type { Objective_Types_Enum } from '~/types/graphql/graphql';

export const useObjective = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // Composables
  const { insertObjective } = objectiveStore();
  const { getConsumptionByMeter } = useApi();

  // Methods
  const createConsumptionObjectiveFromLastYear = async (options: { meter_id: number; site_id: number; objective: number }) => {
    const year = dayjs().subtract(1, 'year').year();

    const consumptions = await getConsumptionByMeter({
      meterId: options.meter_id,
      start: dayjs(`${year}-01-01`).format('YYYY-MM-DD'),
      end: dayjs(`${year}-12-31`).format('YYYY-MM-DD'),
      aggregationLevel: 'month',
    });

    const objectiveMilestones = [];
    consumptions.map((consumption, index) => {
      objectiveMilestones.push({
        timestamp: dayjs(consumption.date).add(1, 'year').add(1, 'month').toDate(),
        target:
          index === 0
            ? Math.round(consumption.consumption_kwh * (1 - options.objective / 100))
            : Math.round(objectiveMilestones[index - 1].target + consumption.consumption_kwh * (1 - options.objective / 100)),
      });
    });

    const objective: InsertObjective = {
      name: `-${options.objective}% vs ${new Date().getFullYear() - 1}`,
      meter_id: options.meter_id,
      target: objectiveMilestones.at(-1)?.target,
      type: 'Cumulated_consumption' as Objective_Types_Enum,
      start: dayjs().startOf('year').toDate(),
      end: dayjs().endOf('year').toDate(),
    };

    await insertObjective({ objective, objectiveMilestones });
  };

  return {
    createConsumptionObjectiveFromLastYear,
  };
};
