<script setup lang="ts">
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';

// Composables
const { createConsumptionObjectiveFromLastYear } = useObjective();
const { energyBySiteAsOption, siteAsOption } = useAsOption();
const { t } = useI18n();

// Props and emits
const modal = defineModel<boolean>();

// Stores
const globalService = globalStore();

// Data
const loading = ref(false);

// Form
const schema = yup.object({
  site: yup.string().required().typeError(t('dashboard.objective_is_required')),
  meter: yup.string().required().typeError(t('dashboard.objective_is_required')),
  objective: yup.number().required(t('dashboard.objective_is_required')).typeError(t('dashboard.objective_is_required')),
});
const { handleSubmit, values, setValues } = useForm<{
  site: number;
  meter: number;
  objective: number;
}>({
  validationSchema: schema,
  initialValues: {
    objective: 0,
  },
});

const submit = handleSubmit(async () => {
  loading.value = true;
  await createConsumptionObjectiveFromLastYear({
    meter_id: values.meter,
    site_id: values.site,
    objective: values.objective,
  });
  await consumptionObjectiveStore().loadConsumptionObjectives();
  loading.value = true;
  modal.value = false;
});

const init = () => {
  const selectedSite = globalStore().getSelectedSite;
  setValues({
    site: selectedSite[0],
    meter: energyBySiteAsOption(selectedSite[0])[0]?.key ?? null,
    objective: values.objective ?? 0,
  });
};
init();

// Lifecycle
watch(
  () => values.site,
  () => {
    setValues({
      meter: energyBySiteAsOption(values.site)[0]?.key ?? null,
      objective: values.objective ?? 0,
    });
  },
);
watch(
  () => modal.value,
  () => {
    if (modal.value) init();
  },
);
</script>

<template>
  <ui-modal v-model="modal" :title="$t('dashboard.create_objective')" :width="500">
    <ui-form-input-select
      :label="$t('global.site')"
      name="site"
      :items="siteAsOption"
      :disabled="globalService.getSelectedSite.length === 1"
    />
    <ui-form-input-select
      v-if="values.site"
      :label="$t('global.energy')"
      name="meter"
      :items="values.site ? energyBySiteAsOption(values.site) : []"
    />
    <ui-form-input-text :label="$t('global.reduction_objective')" name="objective" type="number" right-text="%" />

    <template #footer>
      <ui-button color="secondary" @click="modal = false">
        {{ $t('global.cancel') }}
      </ui-button>
      <ui-button :loading="loading" @click="submit()">
        {{ $t('global.create') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
