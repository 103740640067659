import { provideApolloClient } from '@vue/apollo-composable';
import { defineStore } from 'pinia';
import { OBJECTIVE_INSERT, OBJECTIVE_MILESTONE_INSERT_MANY } from '~/graphql/objective';
import type { ApiInsertObjective, ApiInsertObjectiveMilestone, InsertObjective, InsertObjectiveMilestone } from '~/types/objective';

interface State {
  loading: boolean;
}

export const objectiveStore = defineStore({
  id: 'objectiveStore',
  state: (): State => ({
    loading: false,
  }),
  getters: {},
  actions: {
    async insertObjective(options: { objective: InsertObjective; objectiveMilestones: InsertObjectiveMilestone[] }) {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const objectiveResponse = await apolloClient.mutate<ApiInsertObjective>({
        mutation: OBJECTIVE_INSERT,
        variables: { object: options.objective },
      });

      if (objectiveResponse.data) {
        await apolloClient.mutate<ApiInsertObjectiveMilestone>({
          mutation: OBJECTIVE_MILESTONE_INSERT_MANY,
          variables: {
            objects: options.objectiveMilestones.map((milestone) => ({
              ...milestone,
              objective_id: objectiveResponse.data.insert_objectives_one?.id,
            })),
          },
        });
      }
    },
  },
});
